<template>
  <div class="accounting-general">
    <Banner>
      <div class="banner-content">
        <MeepIconListAccounting class="banner-content__icon" />
        <span class="banner-content__text">{{
            $t("menu.accouting-plan")
          }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :active-tab="'0'"
      :classTab="getClassTab"
      :tabs="accountingData"
      has-search
      @back="goBack"
      @change-tab="changeTab"
    />
    <div class="accounting-general-content page-layout">
      <div class="accounting-general__toolbar">
        <md-list ref="list">
          <md-list-item
            v-for="(item, index) of getSearchs"
            :key="index"
            :class="
              item.content !== undefined ? '' : 'accounting-general__noExpand'
            "
            :md-expand="item.content !== undefined"
          >
            <span class="md-list-item-text">{{ item.label }}</span>
            <div v-if="item.content" slot="md-expand">
              <p
                v-for="(text, innerIndex) of item.content"
                :key="innerIndex"
                class="accounting-general__text"
              >
                {{ text }}
              </p>
            </div>
          </md-list-item>
        </md-list>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconListAccounting from "@/components/icons/MeepIconListAccounting.vue";

import accountingDataGeneral from "./accounting-general.json";
import accountingDataAssociation from "./accounting-association.json";
import accountingDataSci from "./accounting-sci.json";
import HasBackMixin from "@/mixins/HasBackMixin";
import Fuse from "fuse.js";

export default {
  name: "AccountingGeneral",
  components: {
    PageHeader,
    Banner,
    MeepIconListAccounting,
  },
  mixins: [HasBackMixin],
  data() {
    return {
      query: "",
      data: [],
    };
  },
  computed: {
    accountingData() {
      const bigData = [{ id: "0", label: "Tous", data: [] }];
      switch (this.$route.params.id) {
        case "general": {
          accountingDataGeneral["page-tabs"].forEach(item => {
            bigData.push(item);
            bigData[0].data = [...bigData[0].data, ...item.data];
          });
          return bigData;
        }
        case "association": {
          accountingDataAssociation["page-tabs"].map(item => {
            bigData.push(item);
            bigData[0].data = [...bigData[0].data, ...item.data];
          });
          return bigData;
        }
        case "sci": {
          accountingDataSci["page-tabs"].map(item => {
            bigData.push(item);
            bigData[0].data = [...bigData[0].data, ...item.data];
          });
          return bigData;
        }
        default:
          return [];
      }
    },
    getSearchs() {
      if (this.query === "") {
        return this.data;
      }

      const fuse = new Fuse(this.data, {
        shouldSort: true,
        threshold: 0.1,
        distance: 1000,
        keys: ["content", "label"],
      });

      return fuse.search(this.query).map(item => item.item);
    },
  },

  methods: {
    changeTab(tabId) {
      this.$refs.list.$children.map(item => {
        item._data.showContent = false;
        if (item._data.expandStyles !== undefined) {
          item._data.expandStyles.height = 0;
        }
      });
      this.data = this.accountingData[tabId].data;
    },

    getClassTab(tab) {
      if (tab.label === "Tous") {
        return "bbb";
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  .accounting-general {
    .page-header {
      .page-header__item{
        margin-bottom: 2rem;
      }
      .md-tabs-navigation {
        .md-button {
          height: 66px;
          width: 60px;
          font-family: var(--font-extrabold);
          font-size: 20px;

          &:first-child {
            width: 125px;
          }
        }
      }

      .page-search__input {
        @media (max-width: 1280px) {
          width: 250px;
        }
      }
    }

    &-content {
      &.page-layout {
        height: 100%;
      }
    }

    &__toolbar {
      .md-list {
        width: 100%;
        padding: 0;
        background: none;

        .md-list-item-content,
        .md-list-item-content:hover {
          background: #fff;
          border-radius: 15px;
          font-size: 22px;
          transition: 0.5s;
        }

        .md-list-item-content {
          padding: 0;

          .md-list-item-text {
            font-size: 22px;
            padding: 16px 26px;
            font-family: var(--font-extrabold);
          }

          i {
            margin-right: 20px;
            width: 30px;
            height: 30px;
          }
        }

        .md-list-item-expand {
          border: none;
          margin-bottom: 20px;
        }

        .md-list-expand {
          background: #fff;
          border-radius: 15px;
          font-size: 13px;
          font-family: inherit;
          column-count: 3;
        }

        .md-list-item {
          .md-active {
            margin-bottom: 0;

            .md-list-item-content {
              background-color: #fff;
              box-sizing: border-box;
              border: 2px solid var(--bg-primary);
              color: var(--text-secondary);

              .md-icon {
                svg {
                  fill: var(--text-secondary);
                }
              }
            }

            .md-list-expand {
              margin: 35px 0;
              padding: 37px 40px;
            }
          }
        }

        .md-list-item-expand {
          .md-list-item-content:hover {
            background-color: var(--bg-primary) !important;
            color: var(--text-primary) !important;

            .md-icon {
              svg {
                fill: var(--text-primary);
              }
            }
          }
        }
      }
    }

    &__noExpand {
      margin-bottom: 20px;
    }

    &__text {
      white-space: initial;
      word-break: break-word;
    }
  }
}
</style>
